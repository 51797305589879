.avatar {
  width: 26px;
  height: 26px;
  position: relative;
  z-index: 9;

  &.avatar--lg {
    width: 70px;
    height: 70px;
  }
  &.profile_avatar-01 {
    background: url('/assets/avatars/profile_avatar-01.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-02 {
    background: url('/assets/avatars/profile_avatar-02.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-03 {
    background: url('/assets/avatars/profile_avatar-03.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-04 {
    background: url('/assets/avatars/profile_avatar-04.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-05 {
    background: url('/assets/avatars/profile_avatar-05.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-06 {
    background: url('/assets/avatars/profile_avatar-06.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-07 {
    background: url('/assets/avatars/profile_avatar-07.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.profile_avatar-08 {
    background: url('/assets/avatars/profile_avatar-08.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
}
