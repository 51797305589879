.social-bet-stream {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  gap: 8px;
  display: flex;
  flex-direction: column;
  transform-origin: left top;
  align-items: flex-end;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    display: flex;
    flex-direction: row;
    bottom: 15px;
    left: 15px;
    right: 15px;
    align-items: flex-end;
    gap: 10px;
    position: absolute;
    z-index: 100;
  }
}

.social-bet-stream__animated {
  position: relative;

  .social-bet-stream__container {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .social-bet-stream__animated-inner {
    position: absolute;
    bottom: 0;
  }

  .social-bet-stream__bet-item {
    display: flex;
    gap: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}

.show-players-outer {
  @media screen and (max-width: 768px) and (pointer: coarse) {
    flex: 1;
    display: flex;
    position: relative;
  }
}

.show-players {
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding: 4px;
  gap: 0px;
  box-sizing: border-box;
  animation: left-floating-panel-slide-in 1.2s ease-in-out;
  left: 10px;
  flex-direction: column;

  backdrop-filter: var(--primary-backdrop-filter-light);
  -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
  background: rgba(var(--primary-component-background), 0.6);
  box-shadow: var(--primary-background-shadow-with-inset);

  transition: all 500ms ease-in-out;

  width: 180px;
  position: relative;

  &:hover {
    .players-stats {
      .right {
        .icon {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 768px) and (pointer: coarse) {
    &.collapsed {
      background: none;
      box-shadow: none;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
      border: 0;
      box-shadow: none;

      .players-stats {
        background: none;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        border: 0;
        box-shadow: none;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        svg {
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
        }
      }
    }
  }

  &.many-bets {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 80px;
      width: 100%;
      border-radius: 0 0 10px 10px;
      pointer-events: none;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45));
      transition: all 500ms ease-in-out;
    }
  }

  &.collapsed {
    &:after {
      opacity: 0;
    }
    .players-bets {
      opacity: 0;
      height: 0;
      padding-top: 0;
    }
    .players-stats {
      .right {
        .icon {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .players-bets {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px 6px 0;

    max-height: 220px;
    overflow-y: auto;
    overflow-x: visible;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    transition: all 500ms ease-in-out;

    .entry {
      display: flex;
      flex-direction: column;
      gap: 0px;
      position: relative;
      cursor: pointer;

      &.touch-active,
      &:hover {
        .entry-hover-overlay {
          opacity: 1;
          transform: translateX(0);
        }
        .player-name {
          color: #fff;
        }
        .icon {
          opacity: 1;
          transform: translateY(-50%) translateX(0);
        }
      }

      .entry-hover-overlay {
        position: fixed;
        display: none;
        width: 160px;
        top: 60px;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 4px 4px 8px;
        z-index: 99;
        box-shadow: var(--primary-background-shadow-with-inset);
        background: rgba(var(--primary-component-background), 0.8);
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateX(-20px) scale(0.9);
        transition: all 250ms ease-in-out;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;

        pointer-events: none;
        user-select: none;

        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        left: 190px;

        .overlay_header {
          font-family: var(--font-formula-stack);
          font-weight: 600;
          background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
          padding: 4px;
          box-sizing: border-box;
          border-radius: 8px;
          width: 100%;
          display: flex;
          align-items: center;

          .entry_player-name {
            position: relative;
            font-size: 11px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;
            transition: all 150ms ease-in-out;
          }
        }

        &.PickWinner {
          .marbles {
            white-space: nowrap;
            display: flex;
            gap: 8px;
          }
        }
        .arrow-right,
        .value {
          color: rgb(var(--yellow));
        }

        .overlay_bet-type {
          font-size: 10px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 600;
          padding: 0 2px;
          font-family: var(--font-formula-stack);
        }

        .overlay_bet-value {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 2px;

          .label {
            font-size: 10px;
            color: rgba(255, 255, 255, 0.8);
            font-weight: 600;
          }

          .value {
            display: flex;
            gap: 4px;
            font-size: 12px;
            .multiplier {
              color: #fff;
              text-transform: none;
            }
          }
        }
        .overlay_selected-marbles {
          display: flex;
          gap: 4px;
          width: 100%;
          padding: 0 2px;
          .marbles {
            width: 100%;
            .choose-marbles-order {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 4px;
            }
          }
        }
      }

      &:nth-child(-n + 3) {
        .bet-amount {
          font-family: var(--font-formula-stack);

          .amount {
            font-size: 12px;
            font-family: var(--font-formula-stack);
          }
          .multiplier {
            font-size: 12px;
          }
        }
      }

      &:first-child {
        .bet-amount {
          .amount {
            color: rgb(var(--green));
          }
        }
      }

      &:nth-child(2) {
        .bet-amount {
          .amount {
            color: rgb(var(--blue));
          }
        }
      }

      &:nth-child(3) {
        .bet-amount {
          .amount {
            color: rgb(var(--yellow));
          }
        }
      }

      .bet-amount {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        gap: 2px;
      }

      .player-name {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 140px;
        transition: all 150ms ease-in-out;
      }

      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) translateX(0);
        opacity: 0;
        transition: all 150ms ease-in-out;

        svg {
          width: 16px;
          height: 16px;
          fill: rgb(var(--yellow));
        }
      }
    }
  }

  .players-stats {
    display: flex;
    gap: 4px;
    padding: 0 6px;
    background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    padding: 6px;
    font-size: 8px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
    width: 100%;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      gap: 2px;
      white-space: nowrap;
    }

    .right {
      .icon {
        opacity: 0;
        transition: all 250ms ease-in-out;
        svg {
          width: 18px;
          height: 18px;
          fill: currentColor;
          transition: all 500ms ease-in-out;
        }
      }
    }

    .players-stats__total-won {
      font-size: 12px;
      display: flex;
      gap: 4px;

      .value {
        color: rgb(var(--green));

        @media screen and (pointer: fine) {
          animation: text-fancy-color 1.5s linear infinite reverse;

          background: linear-gradient(
            90deg,
            rgba(172, 251, 176, 1) 10%,
            rgba(var(--green), 1) 50%,
            rgba(172, 251, 176, 1) 60%
          );

          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--green), 1);
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .players-stats__total-bets {
      font-size: 12px;
      display: flex;
      gap: 4px;

      .value {
        color: rgb(var(--yellow));

        display: flex;
        align-items: center;
        gap: 4px;
        @media screen and (pointer: fine) {
          animation: text-fancy-color 1.5s linear infinite reverse;
          background: linear-gradient(
            90deg,
            rgb(255, 234, 153) 10%,
            rgba(var(--yellow), 1) 50%,
            rgb(255, 234, 153) 60%
          );

          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--yellow), 1);
          -webkit-text-fill-color: transparent;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: currentColor;
        }
      }
    }
  }
}
