.game-lobby-races {
  display: flex;
  margin-top: 25px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1846px;
  position: relative;
  z-index: 3;
  justify-content: center;
  perspective: 1000px;

  @media screen and (pointer: fine) {
    gap: 0 30px;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) and (pointer: coarse) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.game-lobby-races_entry {
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;

  transition: all 250ms linear;
  will-change: transform, opacity;
  transform-style: preserve-3d;

  transform: rotateY(0) rotateX(0);
  background: linear-gradient(
    160.99deg,
    #f3f3f3 2.56%,
    rgba(var(--primary-component-background), 1) 72.32%
  );
  padding: 1px;
  box-shadow: var(--primary-background-shadow-with-bottom-inset);
  aspect-ratio: 15 / 16;
  width: 100%;
  max-width: 420px;
  max-height: 340px;
  justify-content: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (pointer: fine) {
    margin-bottom: 30px;
    width: 280px;
    max-width: 280px;
    min-width: 280px;
  }

  &.loading {
    pointer-events: none;
    opacity: 0.7;
  }

  &.race_scheduled {
    cursor: default;

    .entry-image {
      opacity: 0.5;
      filter: grayscale(0.2);
    }
    .entry-bottom {
      opacity: 0.5;

      .track-name {
        animation: none;
      }
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: rgb(var(--yellow));
    animation: loading-spinner 1s ease-in-out infinite;
  }

  @keyframes loading-spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .entry-content_inner {
    box-sizing: border-box;
    flex: 1;
    padding: 10px 10px 20px;
    border-radius: 20px;

    background: rgba(var(--primary-component-background), 1);
    display: flex;
    flex-direction: column;
    &::before {
      content: '';
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(38, 41, 49, 0) 65.15%,
        rgba(130, 130, 133, 0.2) 100%
      );
    }
  }

  .current-game-indicator {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    z-index: 4;
    background: rgba(var(--primary-component-background), 1);
    border-radius: 12px 0 12px 0;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(var(--yellow));
    font-family: var(--font-formula-stack);

    @media screen and (max-width: 768px) and (pointer: coarse) {
      top: 5px;
      left: 5px;
      font-size: 10px;
    }
  }

  .entry-image {
    flex: 1;
    border-radius: 14px;
    position: relative;
    max-height: 320px;

    .entry-image-container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 12px;

      .track-video,
      .track-image,
      .preview-thumbnail,
      .preview-image {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 12px;
        object-fit: cover;
        transition: opacity 0.5s ease-in-out;
      }

      .track-image {
        z-index: 1;
        &.opacity-0 {
          opacity: 0;
        }
        &.opacity-100 {
          opacity: 1;
        }
      }

      .preview-thumbnail,
      .track-video {
        z-index: 2;
        &.opacity-0 {
          opacity: 0;
        }
        &.opacity-100 {
          opacity: 1;
        }
      }

      .preview-image {
        z-index: 0;
      }
    }
  }

  .entry-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    position: relative;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateX(var(--rotateX)) rotateY(var(--rotateY))
      translateZ(var(--translateZ));
    transition: transform 150ms linear;

    .track-name {
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 600;
      font-family: var(--font-formula-stack);

      animation: text-fancy-color 3s linear infinite reverse;

      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.8) 10%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.8) 60%
      );
      color: rgba(255, 255, 255, 0.6);

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      -webkit-text-fill-color: transparent;
    }
    .track-details {
      font-size: 11px;
      font-weight: 400;
      font-family: var(--font-formula-stack);

      margin-bottom: 10px;
      display: none;
    }
    .track-events {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      font-family: var(--font-formula-stack);

      .time {
        display: flex;
        align-items: center;
        gap: 10px;
        font-variant-numeric: tabular-nums;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &.hovered {
    transform-style: preserve-3d;

    transform: perspective(1000px) rotateX(var(--rotateX)) rotateY(var(--rotateY))
      translateZ(var(--translateZ)) scale(1.05);

    .entry-image {
      .track-image {
        opacity: 1;
      }
      .preview-image {
        opacity: 0;
      }
    }
  }

  &:first-child {
    animation: reveal 1s ease-in-out;
  }
  &:nth-child(2) {
    animation: reveal 1.1s ease-in-out;
  }
  &:nth-child(3) {
    animation: reveal 1.2s ease-in-out;
  }
  &:nth-child(4) {
    animation: reveal 1.3s ease-in-out;
  }
  &:nth-child(5) {
    animation: reveal 1.4s ease-in-out;
  }
  &:nth-child(6) {
    animation: reveal 1.5s ease-in-out;
  }

  &.race_scheduled {
    .event-label {
      color: rgb(var(--blue));

      background: linear-gradient(
        90deg,
        rgba(163, 220, 234, 1) 10%,
        rgba(var(--blue), 1) 50%,
        rgba(163, 220, 234, 1) 60%
      );

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--blue), 1);
      -webkit-text-fill-color: transparent;
    }
    .time {
      svg {
        fill: rgb(var(--blue));
      }
    }
  }
  &.race_starting,
  &.race_ready {
    .event-label {
      background: linear-gradient(
        90deg,
        rgba(255, 234, 153, 1) 10%,
        rgba(var(--yellow), 1) 50%,
        rgba(255, 234, 153, 1) 60%
      );

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--yellow), 1);
      -webkit-text-fill-color: transparent;
    }
    .time {
      svg {
        fill: rgb(var(--yellow));
      }
    }
  }
  &.race_ended {
    .event-label {
      background: linear-gradient(
        90deg,
        rgba(163, 220, 234, 1) 10%,
        rgba(var(--blue), 1) 50%,
        rgba(163, 220, 234, 1) 60%
      );

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--blue), 1);
      -webkit-text-fill-color: transparent;
    }
    .time {
      svg {
        fill: rgb(var(--blue));
      }
    }
  }
  &.race_started {
    .event-label {
      background: linear-gradient(
        90deg,
        rgba(253, 163, 147, 1) 10%,
        rgba(var(--tomato), 1) 50%,
        rgba(253, 163, 147, 1) 60%
      );

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--tomato), 1);
      -webkit-text-fill-color: transparent;
    }
    .time {
      svg {
        fill: rgb(var(--tomato));
      }
    }
  }
  &.race_settled {
    .event-label {
      background: linear-gradient(
        90deg,
        rgba(172, 251, 176, 1) 10%,
        rgba(var(--green), 1) 50%,
        rgba(172, 251, 176, 1) 60%
      );

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--green), 1);
      -webkit-text-fill-color: transparent;
    }
  }

  &.track_upcoming {
    display: none;
  }

  &.track_upcoming.hovered {
    .track-image {
      opacity: 0;
    }
    .preview-image {
      opacity: 1;
    }
  }
}

@keyframes text-fancy-color {
  to {
    background-position: 200% center;
  }
}
