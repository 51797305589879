@keyframes choose-marbles-selector {
  0% {
    transform: scale(0.4) rotate(25deg) translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0);
    opacity: 1;
  }
}

@keyframes choose-marbles-selector-odds {
  0% {
    transform: scale(0.4) translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}

.choose-marbles {
  display: flex;
  transition: all 250ms ease-in-out;
  flex-direction: column;

  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 12px;

  user-select: none;

  @media screen and (pointer: coarse) {
    order: 1;
    background: rgba(var(--primary-component-background), 0.9);
    border-radius: 12px 12px 0 0;
  }

  .marbles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    transition: all 250ms ease-in-out;

    .marble-outer-container {
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      cursor: pointer;
      position: relative;

      &.active {
        background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
      }

      @media screen and (max-width: 440px) and (pointer: coarse) {
        padding: 12px 6px;
      }

      .marble-outer,
      .marble-odds {
        animation-name: choose-marbles-selector;
        animation-timing-function: ease-in-out;
        transform-origin: center left;
      }

      .marble-odds {
        animation-name: choose-marbles-selector-odds;
      }

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          .marble-outer,
          .marble-odds {
            animation-duration: $i * 100ms;
          }
        }
      }

      &:first-child {
        border-radius: 12px 0 0 0;
      }

      &:nth-child(4) {
        border-radius: 0 12px 0 0;
        border-right: none;
      }

      &:last-child {
        border-right: none;
      }

      @media screen and (pointer: fine) {
        &:hover {
          .marble-outer {
            padding: 4px;
          }
        }
      }

      .marble-color {
        font-size: 8px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        text-transform: uppercase;
      }

      .marble-details {
        display: flex;
        align-content: center;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: 440px) and (pointer: coarse) {
          gap: 8px;
        }
      }
    }

    &.has-equal-odds {
      gap: 10px;
      padding: 0 10px 10px;
      grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));

      .marble-odds {
        display: none;
      }

      .marble-details {
        width: 100%;
      }

      .marble-outer-container {
        padding: 0;
        align-items: center;
        background: none;
        box-shadow: none;

        .marble-outer {
          width: 100%;
          height: auto;
        }

        &:nth-child(4) {
          border-radius: 0;
        }

        &:last-child {
          border-radius: 0 12px 0 0;
        }
      }
    }

  }
  .marble {
    width: 100%;
    height: 100%;
    &.active {
      &::before {
        opacity: 0;
      }
    }
  }

  &.choose-marbles--selector {
    .marble-outer {
      width: 46px;
      height: 46px;

      @media screen and (pointer: coarse) {
        width: 28px;
        height: 28px;
      }
      position: relative;
    }

    .marble-odds {
      font-family: var(--font-formula-stack);
      font-weight: 600;
      position: relative;
      display: flex;
      flex-direction: column;

      &.active {
        .marble-odds-label {
          max-height: 20px;
          opacity: 1;
        }
        .marble-odds-value {
          color: rgb(var(--green));
        }
      }

      .marble-odds-value {
        font-size: 14px;

        @media screen and (max-width: 440px) and (pointer: coarse) {
          font-size: 10px;
        }
      }

      .marble-odds-label {
        font-size: 9px;
        color: rgba(255, 255, 255, 0.75);
        max-height: 0;
        opacity: 0;
        transition: all 250ms ease-in-out;
        text-transform: uppercase;
        white-space: nowrap;

        @media screen and (max-width: 440px) and (pointer: coarse) {
          font-size: 8px;
        }
      }
    }
    .marble-outer {
      &:hover {
        .marble-odds {
          color: rgb(var(--yellow));
        }
      }
    }
    .marble-outer {
      &.active {
        &:hover,
        .marble-odds {
          background: transparent;
          color: rgb(var(--green));
        }
      }
    }

    .marble-exact-order-position {
      opacity: 0;
      transition: all 250ms ease-in-out;

      transform: translate(-50%, 30px);
      left: 50%;
      top: -15px;
      position: absolute;
      font-size: 10px;
      font-family: var(--font-formula-stack);
      font-weight: 600;
      background: rgba(var(--black-color), 0.65);
      padding: 4px 8px;
      border-radius: 6px;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      text-transform: uppercase;
      white-space: nowrap;
      z-index: 9;
      &.visible {
        opacity: 1;
        transform: translate(-50%, 0);

        &.st,
        &.nd,
        &.rd {
          span {
            position: relative;
            animation: text-fancy-color 1.5s linear infinite reverse;
            user-select: none;
            display: flex;
          }
        }

        &.st {
          span {
            background: linear-gradient(
              90deg,
              rgba(172, 251, 176, 1) 10%,
              rgba(var(--green), 1) 50%,
              rgba(172, 251, 176, 1) 60%
            );
            color: rgba(var(--green), 1);
            background-size: 200% auto;
            background-clip: border-box;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        &.nd {
          span {
            background: linear-gradient(
              90deg,
              rgba(255, 234, 153, 1) 10%,
              rgba(var(--yellow), 1) 50%,
              rgba(255, 234, 153, 1) 60%
            );
            color: rgba(var(--yellow), 1);
            background-size: 200% auto;
            background-clip: border-box;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &.rd {
          span {
            background: linear-gradient(
              90deg,
              rgba(163, 220, 234, 1) 10%,
              rgba(var(--blue), 1) 50%,
              rgba(163, 220, 234, 1) 60%
            );
            color: rgba(var(--blue), 1);
            background-size: 200% auto;
            background-clip: border-box;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .marble {
      z-index: 4;
      .number {
        user-select: none;
      }
      &:active {
        transform: scale(1.2);
      }

      &.active {
        transform: rotate(180deg);
        animation: spin-simple 8s linear infinite;

        &:active {
          transform: scale(0.8);
        }

        .number {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
