.marbles-right-sidebar {
  position: absolute;
  right: -400px;
  top: 10px;
  height: 400px;
  width: 420px;
  z-index: 999;
  border-radius: 12px;
  box-shadow: var(--primary-background-shadow);
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-in-out;
  visibility: hidden; /* Hide it by default */
  transform-origin: top right;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    right: 0;
    top: 100%;
    width: 100%;
    //   top: 70px;
    position: fixed;
  }
  &.visible {
    visibility: visible; /* Make it visible */
  }

  &.open {
    right: 10px;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      right: 0;
      top: 60px;
      height: unset;
      bottom: 0;
      border-radius: 12px 12px 0 0;
    }
  }
  &.animating {
    pointer-events: none;
  }
  &.panel-active {
    width: 440px;
    height: calc(100% - 40px);
    bottom: 20px;

    @media screen and (max-width: 890px) and (pointer: fine) {
      height: 600px;
    }

    @media screen and (max-width: 768px) and (pointer: coarse) {
      width: 100%;
      height: calc(100% - 60px);
      bottom: 0;
    }

    .marbles-right-sidebar__player {
      max-height: 0;
      padding: 0;
      opacity: 0;
      gap: 0;
      overflow: hidden;
    }
  }
}

.marbles-right-sidebar__inner {
  position: absolute;
  box-sizing: border-box;
  z-index: 3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  backdrop-filter: var(--primary-backdrop-filter);
  -webkit-backdrop-filter: var(--primary-backdrop-filter);
  background: rgba(var(--primary-component-background), 0.8);

  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    border-radius: 12px 12px 0 0;
  }
}

// marbles-right-sidebar__player
.marbles-right-sidebar__player {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 150ms ease-in-out;

  .player-name {
    display: flex;
    align-items: center;
    gap: 10px;

    .avatar {
      width: 44px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
        fill: rgba(255, 255, 255, 0.55);
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      .label {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.55);
        font-weight: 600;
        font-family: var(--font-formula-stack);
      }
      .value {
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        font-family: var(--font-formula-stack);
      }
    }
  }

  .external_actions {
    display: flex;
    gap: 10px;
    .button {
      margin-top: 10px;
      flex: 1;
      display: flex;
      right: 0;
      top: 1px;
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      overflow: hidden;
      transition: all 500ms ease-in-out;
      user-select: none;
      appearance: none;
      position: relative;
      overflow: hidden;
      padding: 10px;
      border-radius: 8px;
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      font-family: var(--font-formula-stack);
      align-items: center;
      justify-content: center;
      transition: all 150ms ease-in-out;
      cursor: pointer;

      .icon {
        margin-right: 6px;
        position: absolute;
        left: 10px;
        z-index: 3;
        svg {
          width: 16px;
          height: 16px;
          fill: rgba(255, 255, 255, 0.55);
        }
      }

      .link-icon {
        max-width: 0;
        opacity: 0;
        transition: all 250ms ease-in-out;
        position: absolute;
        right: 12px;
        z-index: 3;
        svg {
          width: 12px;
          height: 12px;
          fill: rgba(255, 255, 255, 0.55);
        }
      }

      span {
        z-index: 3;
        position: relative;
      }

      &:active {
        transform: scale(0.92);
      }

      &:hover {
        color: rgb(var(--yellow));
        background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));

        .icon {
          svg {
            fill: rgb(var(--yellow));
          }
        }
        .link-icon {
          max-width: 12px;
          opacity: 1;
          margin-left: 6px;

          svg {
            fill: rgb(var(--yellow));
          }
        }
      }
    }
  }
}

.marbles-right-sidebar__content {
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  flex: 1;
  overflow: scroll;
  scroll-behavior: smooth;
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  .panel-header {
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    font-family: var(--font-formula-stack);
    box-sizing: border-box;
    svg {
      width: 16px;
      height: 16px;
      fill: rgb(var(--green));
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    }

    .back {
      appearance: none;
      background: none;
      border: none;
      height: 100%;
      cursor: pointer;
      padding: 15px;

      border-right: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: rgb(var(--yellow));
      }
      &:active {
        svg {
          transform: scale(0.92);
        }
      }

      svg {
        width: 18px;
        height: 18px;
        fill: currentColor;
      }
    }
  }

  .panel-game-rules {
    padding: 30px 15px 15px;
    box-sizing: border-box;
    position: relative;
    user-select: none;

    .section {
      box-sizing: border-box;
      padding: 0 0 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 30px;
    }

    .rules-faq {
      .entry {
        box-sizing: border-box;
        background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
        padding: 10px;
        border-radius: 8px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        margin-bottom: 15px;
        cursor: pointer;
        transition: all 250ms ease-in-out;
        position: relative;

        p {
          max-height: 0;
          padding: 0;
          overflow: hidden;
          transition: all 250ms ease-in-out;
          opacity: 0;
          position: relative;
        }
        &:active {
          transform: scale(0.92);
        }
        &:hover {
          background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
          border-color: rgba(var(--yellow), 1);
        }

        &.active {
          background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
          border-color: rgba(var(--yellow), 1);
          h5 {
            color: rgb(var(--yellow));
          }
          p {
            padding-top: 4px;
            max-height: 200px;
            opacity: 1;
          }
        }
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: rgb(var(--yellow));
      font-family: var(--font-formula-stack);
      margin: 0 0 15px;
      padding: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      font-family: var(--font-formula-stack);
      margin: 0 0 5px;
      padding: 0;

      &.yellow {
        color: rgb(var(--yellow));
      }
      &.blue {
        color: rgb(var(--blue));
      }
      &.purple {
        color: rgb(var(--purple));
      }
      &.tomato {
        color: rgb(var(--tomato));
      }
    }

    h5 {
      font-size: 14px;
      font-weight: 600;

      svg {
        width: 16px;
        height: 16px;
        fill: currentColor;
        display: inline-block;
        margin-right: 6px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.95);
      margin: 0;
      padding: 0 0 15px;

      svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        fill: rgb(var(--yellow));
      }
    }

    .list-marbles {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      backdrop-filter: blur(5px);
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      border-color: rgba(var(--yellow), 1);

      box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.25);
      padding: 10px;
      border-radius: 10px;

      .marble {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }

    .list-with-icons {
      position: relative;
      li {
        margin-bottom: 5px;
        position: relative;
      }
      .entry {
        position: relative;
        display: inline-flex;
        gap: 8px;
        align-items: center;
        .icon {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
          border-radius: 6px;
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
          text-align: center;
          svg {
            width: 16px;
            height: 16px;
            fill: rgb(var(--yellow));
          }

          .icon-text {
            font-size: 10px;
            line-height: 1;
            font-weight: 600;
            color: rgb(var(--yellow));
            font-family: var(--font-formula-stack);
            text-transform: uppercase;
          }
        }
      }
    }
    .info-box {
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      padding: 10px;
      border-radius: 8px;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      font-size: 12px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.95);

      &.margin-bottom {
        margin-bottom: 15px;
      }
      strong {
        &.green {
          color: rgb(var(--green));
        }
      }
    }
    .rtp-table {
      margin-bottom: 15px;

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
        background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
        font-size: 12px;
        text-align: left;
        thead {
          tr {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            th {
              padding: 10px;
              border-right: 1px solid rgba(255, 255, 255, 0.1);
              &:last-child {
                border-right: none;
              }
            }
          }
        }
        tbody {
          tr {
            background: rgba(var(--primary-component-background), 0.5);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            &:last-child {
              border-bottom: none;
            }
          }
          td {
            padding: 10px;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    ol {
      margin: 0 0 15px 30px;
      list-style: decimal;
      li {
        font-size: 14px;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.95);
      }
    }

    ul {
      margin: 0 0 15px 30px;
      padding: 0 0 15px;
      list-style: disc;

      ul {
        margin: 5px 0 5px 30px;
        padding-bottom: 0;
      }

      &.table-of-contents {
        list-style: none;
        margin-bottom: 0;
        padding-bottom: 0;

        li {
          position: relative;

          a {
            width: 100%;
            display: block;
          }
          &::before {
            content: '';
            position: absolute;
            border-right: 2px solid rgba(255, 255, 255, 0.5);
            border-bottom: 2px solid rgba(255, 255, 255, 0.5);
            width: 8px;
            height: 8px;
            top: calc(50% + 1px);
            left: -20px;
            transform: translateY(-50%) rotate(-45deg);
          }
          &:hover {
            &::before {
              border-right: 2px solid rgb(var(--yellow));
              border-bottom: 2px solid rgb(var(--yellow));
            }
          }
        }
      }
      li {
        font-size: 14px;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.95);

        a {
          &:hover {
            color: rgb(var(--yellow));
            text-decoration: underline;
          }
        }
      }
    }

    strong {
      &.yellow {
        color: rgb(var(--yellow));
      }
      &.tomato {
        color: rgb(var(--tomato));
      }
      &.purple {
        color: rgb(var(--purple));
      }
      &.blue {
        color: rgb(var(--blue));
      }
      &.green {
        color: rgb(var(--green));
      }
    }

    .time-label {
      font-size: 12px;
      line-height: 1;
      font-style: italic;
      color: rgba(255, 255, 255, 0.75);
      font-weight: 700;
      margin-left: 4px;
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      padding: 4px;
      border-radius: 4px;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    }

    .inline-label {
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      padding: 0 6px;
      border-radius: 6px;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      text-align: center;

      font-size: 10px;
      line-height: 1;
      font-weight: 600;
      color: rgb(var(--yellow));
      font-family: var(--font-formula-stack);
      text-transform: uppercase;

      &.green {
        color: rgb(var(--green));
      }
      &.yellow {
        color: rgb(var(--yellow));
      }
      &.blue {
        color: rgb(var(--blue));
      }
    }
  }

  .panel-content {
    padding: 30px 15px 15px;
    box-sizing: border-box;
    position: relative;
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      font-family: var(--font-formula-stack);
      margin: 0 0 15px;
      padding: 0;
      user-select: none;
    }

    p {
      font-size: 12px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
      padding: 0 0 15px;
    }
  }

  .toast p {
    padding: 0;
  }
}

.audio-controls-panel {
  position: absolute;
  width: 300px;
  top: 54px;
  right: 52px;
  z-index: 9999;

  box-shadow: var(--primary-background-shadow-with-inset);
  backdrop-filter: var(--primary-backdrop-filter);
  -webkit-backdrop-filter: var(--primary-backdrop-filter);
  background: rgba(var(--primary-component-background), 0.8);
  border-radius: 12px;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    right: 0;
  }

  .panel-content_section {
    background: none;
    box-shadow: none;
    margin-bottom: 0;
    animation: none;
    padding: 5px;
  }

  .panel-audio-controls {
    display: flex;
    flex-direction: column;

    .panel-audio-controls__section {
      padding: 5px;

      &:first-child {
        border-radius: 10px 10px 0 0;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.03));
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
        background: linear-gradient(rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.04));
      }
    }
  }
}

.panel-content_section {
  background: rgba(255, 255, 255, 0.05);
  padding: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 30px;
  animation: reveal 1.2s ease-in-out;

  .save-btn {
    display: flex;
    flex: none;
    height: 35px;
    margin: 0;
    align-items: center;
    justify-content: center;
  }

  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    h3 {
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      font-family: var(--font-formula-stack);
      margin: 0;
      padding: 0;
    }

    .close-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        svg {
          fill: rgb(var(--yellow));
          transform: rotate(180deg);
        }
      }

      svg {
        transition: all 150ms ease-in-out;
        width: 16px;
        height: 16px;
        fill: #fff;
      }
    }
  }

  label {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    .label {
      flex: 1;
      min-width: 80px;
      white-space: nowrap;
    }
    .volume-value {
      font-variant-numeric: tabular-nums;
      color: rgb(var(--yellow));
      min-width: 34px;
      text-align: left;
    }

    .count {
      font-variant-numeric: tabular-nums;
      color: rgb(var(--yellow));
      min-width: 30px;
      height: 30px;
      border-radius: 6px;
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(var(--marbles-container-dark-blue), 0.75);
    }

    input {
      flex: 1;
      background: rgba(var(--primary-dark-color), 0.5);

      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      border-radius: 6px;
      padding: 10px;
      color: #fff;
      appearance: none;
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      width: 100%;
      box-sizing: border-box;
      font-family: var(--font-formula-stack);
      outline: none;
      &:focus,
      &:hover {
        background: rgba(var(--primary-dark-color), 1);
      }
    }

    // input type checkbox
    &.checkbox-outer {
      display: flex;
      align-items: center;
      cursor: pointer;

      .checkbox-wrapper {
        position: relative;
        width: 20px;
        height: 20px;
      }

      input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkbox-inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background: rgba(var(--primary-dark-color), 0.5);
        border-radius: 4px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        transition: all 0.2s ease-in-out;
      }

      input[type='checkbox']:checked ~ .checkbox-inner {
        background: linear-gradient(rgb(var(--green)), rgba(var(--dark-green), 1));
        box-shadow:
          0 1px 4px rgba(0, 0, 0, 0.4),
          inset 0 -2px 0 rgba(0, 0, 0, 0.2);
      }

      .checkbox-inner:after {
        content: '';
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
      }

      input[type='checkbox']:checked ~ .checkbox-inner:after {
        display: block;
      }
    }

    // input type range
    input[type='range'] {
      cursor: pointer;

      &::-webkit-slider-runnable-track {
        background: rgba(var(--yellow), 1);
        height: 0.5rem;
        border-radius: 999em;
      }
      &::-moz-range-track {
        background: rgba(var(--yellow), 1);
        height: 0.5rem;
        border-radius: 999em;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -4px; /* Centers thumb on the track */
        background: linear-gradient(rgb(var(--green)), rgba(var(--dark-green), 1));
        box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.2);
        border-radius: 999em;
        height: 1rem;
        width: 1rem;
      }
      &::-moz-range-thumb {
        border: none; /*Removes extra border that FF applies*/
        border-radius: 0; /*Removes default border-radius that FF applies*/
        background: linear-gradient(rgb(var(--green)), rgba(var(--dark-green), 1));
        box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.2);
        border-radius: 999em;
        height: 1rem;
        width: 1rem;
      }
      &:focus {
        outline: none;
      }
      &:focus::-webkit-slider-thumb {
        border: 0;
        outline: 1px solid rgb(var(--green));
        box-shadow:
          inset 0 2px 0 rgba(0, 0, 0, 0.2),
          inset 0 -2px 0 rgba(0, 0, 0, 0.2);

        outline-offset: 0.125rem;
      }
      &:focus::-moz-range-thumb {
        border: 0;
        outline: 1px solid rgb(var(--green));
        outline-offset: 0.125rem;
        box-shadow:
          inset 0 2px 0 rgba(0, 0, 0, 0.2),
          inset 0 -2px 0 rgba(0, 0, 0, 0.2);
      }
    }
    select {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;
      padding: 10px;
      border: none;
      background: transparent;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      font-family: var(--font-formula-stack);
      appearance: none;
      cursor: pointer;
      user-select: none;
      box-sizing: border-box;
      border-radius: 6px;
      background: rgba(var(--primary-dark-color), 0.5);
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      outline: none;

      &:focus,
      &:hover {
        background: rgba(var(--primary-dark-color), 1);
      }
    }

    .inline-btn {
      appearance: none;
      background: linear-gradient(rgba(var(--primary-dark-color), 0.5), rgba(var(--green), 0.2));
      border-radius: 10px;
      box-shadow:
        inset 0 1px 0 rgba(var(--green), 0.25),
        inset 0 -1px 0 rgba(var(--green), 0.25);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(var(--green));
      transition: all 150ms ease-in-out;

      &.muted {
        color: rgb(var(--red));
        background: linear-gradient(rgba(var(--primary-dark-color), 0.5), rgba(var(--red), 0.2));
        box-shadow:
          inset 0 1px 0 rgba(var(--red), 1),
          inset 0 -1px 0 rgba(var(--red), 0.75);
      }
      svg {
        width: 16px;
        height: 16px;
        fill: currentColor;
        user-select: none;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }

  .input__error-message {
    white-space: break-spaces;
    font-size: 12px;
    margin-bottom: 5px;
    color: rgb(var(--tomato));
  }
}

.marbles-right-sidebar__nav {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 10px;
  padding: 15px 0;
  position: relative;
  z-index: 3;
  align-items: flex-end;

  &.panel-active {
    button {
      .label {
        opacity: 0;
        user-select: none;
        pointer-events: none;
      }

      &.close {
        margin-bottom: 20px;
      }
    }
  }

  button {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 15px;
    user-select: none;
    appearance: none;
    border: 0;
    transition: all 150ms ease-in-out;
    position: relative;

    &:before {
      transition: all 250ms ease-in-out;
      content: '';
      left: 0;
      border-radius: 0 2px 2px 0;
      top: 50%;
      transform: translateY(-50%);
      height: 15px;

      width: 2px;
      position: absolute;
    }

    &.close {
      margin-bottom: 90px;

      svg {
        transition: 250ms ease-in-out;
      }
      &:hover {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    &.active:hover,
    &.active {
      color: rgb(var(--yellow));
      transform: scale(0.92);

      &::before {
        box-shadow:
          0 0 20px 0 rgba(0, 0, 0, 0.5),
          0 0 4px rgba(var(--green), 0.6);
        background: rgb(var(--green));
      }
      .inner {
        background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
      }

      .icon {
        color: rgb(var(--green));
      }
    }
    &:hover {
      color: rgb(var(--yellow));

      &::before {
        box-shadow:
          0 0 20px 0 rgba(0, 0, 0, 0.5),
          0 0 4px rgba(var(--yellow), 0.6);
        width: 2px;
        position: absolute;
        background: rgb(var(--yellow));
      }

      .icon {
        color: rgba(var(--yellow), 1);
      }
    }
    .icon {
      position: relative;
      z-index: 3;
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        fill: currentColor;
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
      }
    }
    .label {
      font-size: 12px;
      font-weight: 600;
      color: currentColor;
      font-family: var(--font-formula-stack);
      right: 85px;
      white-space: nowrap;
      position: absolute;
      transition: all 150ms ease-in-out;
      opacity: 1;
    }
    .inner {
      color: currentColor;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
      border-radius: 10px;
      box-sizing: border-box;
      padding: 10px;
      cursor: pointer;
      transition: all 250ms ease-in-out;
      position: relative;
      overflow: hidden;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      color: #fff;
      user-select: none;
    }
  }
}

// race-history-sidebar
// past-races-sidebar
.past-races-sidebar,
.race-history-sidebar {
  display: flex;
  flex-direction: column;
  padding: 15px;

  h3 {
    font-family: var(--font-formula-stack);
    font-weight: 600;
    font-size: 14px;
    animation: text-fancy-color 1.5s linear infinite reverse;
    display: flex;
    margin: 0 0 10px;
    padding: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 234, 153, 1) 10%,
      rgba(var(--yellow), 1) 50%,
      rgba(255, 234, 153, 1) 60%
    );

    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% auto;
    color: rgba(var(--yellow), 1);
    -webkit-text-fill-color: transparent;
  }
}

// race-stats
.race-stats {
  display: flex;
  flex-direction: column;
  padding: 15px;
  h3 {
    font-family: var(--font-formula-stack);
    font-weight: 600;
    font-size: 14px;
    animation: text-fancy-color 1.5s linear infinite reverse;
    display: flex;
    margin: 0 0 10px;
    padding: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 234, 153, 1) 10%,
      rgba(var(--yellow), 1) 50%,
      rgba(255, 234, 153, 1) 60%
    );

    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% auto;
    color: rgba(var(--yellow), 1);
    -webkit-text-fill-color: transparent;
  }
}

.race-stats__table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0 0 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background: rgba(var(--marbles-container-dark-blue), 0.75);
  overflow: hidden;
  animation: reveal 1s ease-in-out;

  box-shadow:
    inset 0 1px 0 rgba(var(--yellow), 0.8),
    inset 0 -1px 0 rgba(var(--yellow), 0.6);

  &.table-first-long {
    .head,
    .body .row {
      grid-template-columns: 50px repeat(2, 1fr);
    }
  }

  &.table-four-column {
    .head,
    .body .row {
      grid-template-columns: 20px repeat(3, 1fr);
    }
  }
  .head,
  .body .row {
    display: grid;
    grid-template-columns: 20px repeat(4, 1fr);

    box-sizing: border-box;
    align-items: center;
    font-family: var(--font-formula-stack);
    font-size: 11px;
    color: #fff;
  }

  .marble-label,
  .marble-entry {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .marble-pair {
    display: flex;
    gap: 5px;
    flex-shrink: 0;
  }

  .head {
    background: rgba(var(--marbles-dark-blue), 0.5);
    border-radius: 10px 10px 0 0;
    font-weight: 600;
    margin: 1px 0 0;
    padding: 10px 10px;
    gap: 10px;
    text-transform: uppercase;

    span {
      display: flex;
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 0;
    box-sizing: border-box;
    padding: 0 5px;

    .row {
      font-weight: 400;
      border-radius: 24px;
      padding: 4px 5px;
      gap: 10px;

      &:nth-child(even) {
        background: linear-gradient(
          rgba(var(--marbles-dark-blue), 0.4),
          rgba(var(--marbles-dark-blue), 0.8)
        );
      }

      &:first-child {
        animation: reveal 1.2s ease-in-out;
      }
      &:nth-child(2n) {
        animation: reveal 1.4s ease-in-out;
      }
      &:nth-child(3n) {
        animation: reveal 1.6s ease-in-out;
      }
      &:nth-child(4n) {
        animation: reveal 1.8s ease-in-out;
      }
      &:nth-child(5n) {
        animation: reveal 2s ease-in-out;
      }
      &:nth-child(6n) {
        animation: reveal 2.2s ease-in-out;
      }
      &:nth-child(7n) {
        animation: reveal 2.4s ease-in-out;
      }
      &:nth-child(8n) {
        animation: reveal 2.6s ease-in-out;
      }

      .green {
        color: rgb(var(--green));
      }
      .yellow {
        color: rgb(var(--yellow));
      }
      .tomato {
        color: rgb(var(--tomato));
      }
    }
  }
}
