.idle-timer-banner {
  z-index: 999;
  background-color: rgba(var(--black-color), 0.8) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .inner {
    background: rgba(var(--primary-component-background), 0.8);
    backdrop-filter: var(--primary-backdrop-filter);

    border-radius: 24px;
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 20px);
    max-width: 500px;
    flex-shrink: 0;
    overflow: hidden;
    padding: 16px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--primary-background-shadow-with-inset);

    p {
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 400;
      color: rgba(var(--white-color));
      text-align: center;
      &.heading {
        font-family: var(--font-formula-stack);
        font-size: 14px;
        margin: 0 0 10px;
        font-weight: 600;
        animation: text-fancy-color 3s linear infinite reverse;
        background: linear-gradient(
          80deg,
          rgba(255, 255, 255, 0.8) 10%,
          rgba(255, 255, 255, 0.4) 50%,
          rgba(255, 255, 255, 0.8) 60%
        );
        color: rgba(255, 255, 255, 0.6);
        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        -webkit-text-fill-color: transparent;
      }
    }

    button {
      background: rgba(var(--white-color), 0.15);
      border: none;
      border-radius: 16px;
      width: 100%;
      padding: 16px;
      font-family: var(--font-formula-stack);
      font-size: 12px;
      font-weight: 600;
      color: rgba(var(--white-color));
      appearance: none;
      user-select: none;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: rgba(var(--white-color), 0.25);
        color: rgba(var(--yellow));
      }
    }
  }
}

.banner-blobs {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
  .blob-a {
    position: absolute;
    left: 10%;
    width: 50px;
    height: 150px;
    filter: blur(34px);
    border-radius: 999em;
    transform: rotate(-35deg);
    background: rgba(var(--yellow), 1);
    animation: blob-a 2s infinite ease-in-out;
  }
  .blob-b {
    position: absolute;
    width: 100px;
    left: calc(50% - 50px);
    height: 150px;
    filter: blur(34px);
    border-radius: 999em;
    background: rgba(var(--green), 1);
    animation: blob-b 2s infinite ease-in-out;
  }
  .blob-c {
    position: absolute;
    right: 10%;
    width: 50px;
    height: 150px;
    filter: blur(34px);
    border-radius: 999em;
    transform: rotate(35deg);
    background: rgba(var(--blue), 1);
    animation: blob-c 2s infinite ease-in-out;
  }
}

@keyframes blob-a {
  0% {
    transform: rotate(-35deg) scale(1);
  }
  50% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: rotate(-35deg) scale(1);
  }
}

@keyframes blob-c {
  0% {
    transform: rotate(35deg) scale(1);
  }
  50% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: rotate(35deg) scale(1);
  }
}

@keyframes blob-b {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.banner-results {
  display: flex;
  margin: 10px 0 0;
  padding-bottom: 16px;
  position: relative;
  z-index: 9;
  width: 100%;
  border-radius: 0 0 24px 24px;
  justify-content: center;
  box-shadow: inset 0 -1px 0 rgb(var(--green));
  .result_entries {
    display: flex;
    gap: 15px;
    box-sizing: border-box;
    width: calc(100% - 60px);
    justify-content: center;
  }
  .entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    position: relative;
    &:first-child {
      order: 2;

      .marble-wrapper {
        .marble {
          animation: first-winner 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          transform-origin: center;
        }
      }
    }

    &:nth-child(2) {
      order: 1;

      .marble-wrapper {
        width: 70px;
        height: 70px;

        .marble {
          img {
            animation: second-winner 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          }
        }

        &::before,
        &::after {
          border-color: rgb(var(--yellow));
        }
      }
      .marble_blur {
        background: rgba(var(--yellow), 0.9);
      }
      .marble_label {
        .marble-position {
          font-size: 14px;
          background: linear-gradient(
            90deg,
            rgba(255, 234, 153, 1) 10%,
            rgba(var(--yellow), 1) 50%,
            rgba(255, 234, 153, 1) 60%
          );

          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--yellow), 1);
          -webkit-text-fill-color: transparent;
        }
        &::before,
        &::after {
          border-color: rgb(var(--yellow));
        }
      }
    }

    &:last-child {
      order: 3;
      .marble-wrapper {
        width: 60px;
        height: 60px;
        &::before,
        &::after {
          border-color: rgb(var(--blue));
        }

        .marble {
          img {
            animation: third-winner 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          }
        }
      }
      .marble_blur {
        background: rgba(var(--blue), 0.9);
      }
      .marble_label {
        .marble-position {
          font-size: 12px;
          background: linear-gradient(
            90deg,
            rgba(163, 220, 234, 1) 10%,
            rgba(var(--blue), 1) 50%,
            rgba(163, 220, 234, 1) 60%
          );

          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--blue), 1);
          -webkit-text-fill-color: transparent;
        }
        &::before,
        &::after {
          border-color: rgb(var(--blue));
        }
      }
    }
    @keyframes first-winner {
      0% {
        transform: translateY(0) scale(1) rotate(0deg);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      15% {
        transform: translateY(-5px) scale(1.02) rotate(180deg);
        animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      }
      30% {
        transform: translateY(0) scale(0.95) rotate(360deg);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      45% {
        transform: translateY(-5px) scale(1.05) rotate(375deg);
        animation-timing-function: ease-in-out;
      }
      60% {
        transform: translateY(0) scale(1) rotate(350deg);
        animation-timing-function: ease-in-out;
      }
      75% {
        transform: scale(1.02) rotate(370deg);
        animation-timing-function: ease-in-out;
      }
      90% {
        transform: scale(0.98) rotate(350deg);
        animation-timing-function: ease-in-out;
      }
      100% {
        transform: scale(1) rotate(360deg);
      }
    }

    @keyframes second-winner {
      0% {
        transform: rotate(-10deg);
      }
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-10deg);
      }
    }

    @keyframes third-winner {
      0% {
        transform: scaleX(-1) rotate(-12deg);
      }
      50% {
        transform: scaleX(-1) rotate(0deg);
      }
      100% {
        transform: scaleX(-1) rotate(-12deg);
      }
    }

    .marble-wrapper {
      background: rgba(0, 0, 0, 0.6);
      box-shadow:
        0 0 15px rgba(0, 0, 0, 0.35),
        0 0 2px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      padding: 10px;
      border-radius: 999em;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        border-top: 1px solid rgb(var(--green));
        border-radius: 999em 999em 0 0;
        transform: rotate(0deg);
        animation: rotate 5s infinite linear reverse;
        transform-origin: center bottom;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid rgb(var(--green));
        border-radius: 0 0 999em 999em;
        transform: rotate(0deg);
        animation: rotate 5s infinite linear;
        transform-origin: center top;
      }
    }
    .marble_blur {
      position: absolute;
      top: 0;
      left: 45%;
      right: 45%;
      bottom: 0;
      background: rgba(var(--green), 0.9);
      filter: blur(10px);
      animation: rotate 5s infinite linear;
    }
    .marble {
      .marble_number {
        font-family: var(--font-formula-stack);
        font-size: 16px;
        font-weight: 600;
        position: relative;
        z-index: 4;
      }
      &::before {
        display: none;
      }
    }
    .marble-name {
      font-family: var(--font-formula-stack);
      font-size: 10px;
      font-weight: 600;
      z-index: 9;

      // Color for name
      $marble-colors: (
        'Midnight': var(--midnight),
        'Ocean': var(--ocean),
        'Atlas': var(--atlas),
        'Nemo': var(--nemo),
        'Princess': var(--princess),
        'Inferno': var(--inferno),
        'Toothpaste': var(--toothpaste),
        'Bee': var(--bee)
      );

      @each $name, $color in $marble-colors {
        &.marble-#{$name} {
          color: rgb($color);
        }
      }
    }
    .marble_label {
      background: rgb(var(--primary-dark-color));
      border-radius: 12px;
      box-sizing: border-box;
      padding: 6px 0;
      position: relative;
      top: -12px;
      display: flex;
      flex-direction: column;
      width: 100%;
      .marble-position {
        font-family: var(--font-formula-stack);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        animation: text-fancy-color 1.5s linear infinite reverse;

        background: linear-gradient(
          90deg,
          rgba(172, 251, 176, 1) 10%,
          rgba(var(--green), 1) 50%,
          rgba(172, 251, 176, 1) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--green), 1);
        -webkit-text-fill-color: transparent;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        border-top: 1px solid rgb(var(--green));
        border-radius: 12px 12px 0 0;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 50%;
        border-bottom: 1px solid rgb(var(--green));
        border-radius: 0 0 12px 12px;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes open-up-animation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.broadcast-overlay__stats {
  position: absolute;
  z-index: 18;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 340px;
  user-select: none;
  pointer-events: none;
  visibility: hidden;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    top: 0;
    max-width: unset;
    left: 0;
    right: 0;
    height: calc(100svh - 260px);
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    transform: scale(0.8);
    z-index: 200;
  }

  @media screen and (max-width: 740px) and (pointer: fine) {
    top: 30%;
  }

  @media screen and (max-width: 640px) and (pointer: fine) {
    top: 10%;
  }

  .entries {
    position: relative;
    opacity: 0;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      min-width: 300px;
    }

    &.active {
      animation: open-up-animation 1s ease-in-out;
      visibility: visible;
    }

    .countdown-timer {
      font-family: var(--font-formula-stack);
      font-size: 18px;
      font-weight: 600;
      color: rgb(var(--yellow));
      margin-left: 10px;
      animation: pulse 1s infinite;
    }
  }

  .entries.active {
    opacity: 1;
    transform: scale(1);
  }

  .entries.exit {
    opacity: 0;
    transition: all 250ms ease-in-out;
  }

  .entry-box {
    display: flex;
    box-sizing: border-box;
    border-radius: 24px;

    backdrop-filter: var(--primary-backdrop-filter);
    -webkit-backdrop-filter: var(--primary-backdrop-filter);
    background: rgba(var(--primary-component-background), 0.8);
    box-shadow: var(--primary-background-shadow);

    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    position: relative;
    z-index: 3;
    transition: all 250ms ease-in-out;
    overflow: hidden;

    flex-direction: column;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      left: -30px;
      right: -30px;
      top: 4px;
      bottom: 4px;
      background: linear-gradient(rgba(var(--blue), 0) 0%, rgba(var(--primary-dark-color), 1) 100%),
        radial-gradient(
          ellipse farthest-side at center top,
          rgba(var(--blue), 0.8) 0%,
          rgb(var(--primary-dark-color)) 150%,
          rgb(var(--primary-dark-color)) 100%
        );
      opacity: 0.2;
      z-index: 2;
      border-radius: 24px;
    }

    &.race_settled {
      margin-top: 10px;
      &::after {
        background: linear-gradient(
            rgba(var(--pink), 0) 0%,
            rgba(var(--primary-dark-color), 1) 100%
          ),
          radial-gradient(
            ellipse farthest-side at center top,
            rgba(var(--purple), 0.8) 0%,
            rgb(var(--primary-dark-color)) 150%,
            rgb(var(--primary-dark-color)) 100%
          );
      }
      .content {
        box-shadow: inset 0 1px 0 rgb(var(--green));

        .data {
          color: rgb(var(--green));
          .data__inner {
            span {
              background: linear-gradient(
                90deg,
                rgba(172, 251, 176, 1) 10%,
                rgba(var(--green), 1) 50%,
                rgba(172, 251, 176, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--green), 1);
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }

    &.race_scheduled {
      display: none;
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.25);

      &::after {
        background: linear-gradient(
            rgba(var(--blue), 0) 0%,
            rgba(var(--primary-dark-color), 1) 100%
          ),
          radial-gradient(
            ellipse farthest-side at center top,
            rgba(var(--blue), 0.8) 0%,
            rgb(var(--primary-dark-color)) 150%,
            rgb(var(--primary-dark-color)) 100%
          );
      }
      .content {
        box-shadow:
          inset 0 1px 0 rgb(var(--blue)),
          inset 0 -1px 0 rgb(var(--blue));
        border-radius: 24px;

        .data {
          color: rgb(var(--blue));

          .data__inner {
            span {
              background: linear-gradient(
                90deg,
                rgba(163, 220, 234, 1) 10%,
                rgba(var(--blue), 1) 50%,
                rgba(163, 220, 234, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--blue), 1);
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    &.race_starting,
    &.race_ready {
      display: none;
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.25);

      &::after {
        background: linear-gradient(
            rgba(var(--yellow), 0) 0%,
            rgba(var(--marbles-container-dark-blue), 1) 100%
          ),
          radial-gradient(
            ellipse farthest-side at center top,
            rgba(var(--yellow), 0.8) 0%,
            rgb(var(--marbles-container-dark-blue)) 150%,
            rgb(var(--marbles-container-dark-blue)) 100%
          );
      }
      .content {
        box-shadow:
          inset 0 1px 0 rgb(var(--yellow)),
          inset 0 -1px 0 rgb(var(--yellow));
        border-radius: 24px;

        .data {
          color: rgb(var(--yellow));

          .data__inner {
            span {
              background: linear-gradient(
                90deg,
                rgba(255, 234, 153, 1) 10%,
                rgba(var(--yellow), 1) 50%,
                rgba(255, 234, 153, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--yellow), 1);
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    &.race_started {
      display: none;
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.25);

      &::after {
        background: linear-gradient(
            rgba(var(--tomato), 0) 0%,
            rgba(var(--marbles-container-dark-blue), 1) 100%
          ),
          radial-gradient(
            ellipse farthest-side at center top,
            rgba(var(--tomato), 0.8) 0%,
            rgb(var(--marbles-container-dark-blue)) 150%,
            rgb(var(--marbles-container-dark-blue)) 100%
          );
      }
      .content {
        box-shadow:
          inset 0 1px 0 rgb(var(--tomato)),
          inset 0 -1px 0 rgb(var(--tomato));
        border-radius: 24px;

        .data {
          color: rgb(var(--tomato));

          .data__inner {
            span {
              background: linear-gradient(
                90deg,
                rgba(253, 163, 147, 1) 10%,
                rgba(var(--tomato), 1) 50%,
                rgba(253, 163, 147, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--tomato), 1);
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    &.race_ended {
      display: none;
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.25);

      &::after {
        background: linear-gradient(
            rgba(var(--purple), 0) 0%,
            rgba(var(--marbles-container-dark-blue), 1) 100%
          ),
          radial-gradient(
            ellipse farthest-side at center top,
            rgba(var(--purple), 0.8) 0%,
            rgb(var(--marbles-container-dark-blue)) 150%,
            rgb(var(--marbles-container-dark-blue)) 100%
          );
      }
      .content {
        box-shadow:
          inset 0 1px 0 rgb(var(--purple)),
          inset 0 -1px 0 rgb(var(--purple));
        border-radius: 24px;

        .data {
          color: rgb(var(--purple));

          .data__inner {
            span {
              background: linear-gradient(
                90deg,
                rgba(241, 167, 255, 1) 10%,
                rgba(var(--purple), 1) 50%,
                rgba(241, 167, 255, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--purple), 1);
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }

    &.race_cancelled {
      display: none;
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.25);

      &::after {
        background: linear-gradient(
            rgba(var(--red), 0) 0%,
            rgba(var(--marbles-container-dark-blue), 1) 100%
          ),
          radial-gradient(
            ellipse farthest-side at center top,
            rgba(var(--purple), 0.8) 0%,
            rgb(var(--marbles-container-dark-blue)) 150%,
            rgb(var(--marbles-container-dark-blue)) 100%
          );
      }
      .content {
        box-shadow:
          inset 0 1px 0 rgb(var(--red)),
          inset 0 -1px 0 rgb(var(--red));
        border-radius: 24px;

        .data {
          color: rgb(var(--red));

          .data__inner {
            span {
              background: linear-gradient(
                90deg,
                rgba(253, 163, 147, 1) 10%,
                rgba(var(--red), 1) 50%,
                rgba(253, 163, 147, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--red), 1);
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }

    .icon {
      background: rgba(255, 255, 255, 0.08);

      width: 100%;
      height: 70px;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 3;

      svg {
        width: 32px;
        height: 32px;
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
        fill: currentColor;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      box-sizing: border-box;
      position: relative;
      z-index: 3;
      padding: 15px;
      align-items: center;
      width: 100%;
      border-radius: 24px 24px 0 0;
      box-shadow: inset 0 1px 0 rgb(var(--yellow));

      .data {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
        color: rgb(var(--yellow));
        svg {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          fill: currentColor;
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));

          path:first-child {
            animation: opacity-angle-double-first 1.5s infinite ease-in-out;
          }
          path:last-child {
            animation: opacity-angle-double-last 1.5s infinite ease-in-out;
            opacity: 0.4;
          }

          @keyframes opacity-angle-double-first {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0.4;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes opacity-angle-double-last {
            0% {
              opacity: 0.4;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.4;
            }
          }
        }

        .data__inner {
          display: flex;
          flex: 1;
          gap: 5px;
          flex-direction: column;

          span {
            font-family: var(--font-formula-stack);
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            animation: text-fancy-color 3s linear infinite reverse;
            user-select: none;
          }
        }
      }

      .label {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 600;
      }
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes waiting-animation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@keyframes text-fancy-color {
  to {
    background-position: 200% center;
  }
}

.banner-won {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  z-index: 3;

  border-radius: 0;
  width: calc(100% - 20px);
  box-shadow: 0 -1px 0 rgb(var(--green));
  border-radius: 16px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  overflow: hidden;
  background: rgba(var(--primary-dark-color), 0.8);

  .won-text {
    z-index: 3;
    text-transform: uppercase;
    animation: text-fancy-color 3s linear infinite reverse;
    user-select: none;
    font-size: 16px;
    font-family: var(--font-formula-stack);
    font-weight: 600;

    background: linear-gradient(
      90deg,
      rgba(255, 234, 153, 1) 10%,
      rgba(var(--yellow), 1) 50%,
      rgba(255, 234, 153, 1) 60%
    );

    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% auto;
    color: rgba(var(--yellow), 1);
    -webkit-text-fill-color: transparent;
  }

  .won-amount {
    z-index: 3;
    text-transform: uppercase;
    animation: text-fancy-color 3s linear infinite reverse;
    user-select: none;
    font-size: 24px;
    font-weight: 600;
    font-variant-numeric: tabular-nums;

    font-family: var(--font-formula-stack);
    background: linear-gradient(
      90deg,
      rgba(var(--green)) 10%,
      rgba(var(--yellow), 1) 50%,
      rgba(var(--green)) 60%,
      rgba(var(--green)) 75%
    );

    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% auto;
    color: rgba(var(--yellow), 1);
    -webkit-text-fill-color: transparent;
  }
}

.trends-overlay {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-35%, -50%);
  background: rgba(var(--marbles-container-dark-blue), 0.85);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.25),
    inset 0 -1px 0 rgba(255, 255, 255, 0.25),
    0 0 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  z-index: 9;
  width: 100%;
  max-width: 420px;
  max-height: 100%;
  overflow-y: auto;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    left: 40px;
    right: 40px;
    transform: none;
    max-width: calc(100% - 80px);
    top: 90px;

    .trends-overlay__content {
      h3 {
        font-size: 12px;
        margin: 0 0 10px;
      }
    }

    .race-stats__table {
      .row:nth-child(5),
      .row:last-child {
        display: none;
      }
    }
  }
  &::before {
    content: '';
    background:
      linear-gradient(rgba(37, 41, 55, 0), rgba(37, 41, 55, 0.4)),
      url(/assets/marbles-racepattern-bg.png) repeat center top;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 10px;

    top: 0;
    z-index: 1;
    bottom: 0;
    opacity: 0.2;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 4px;
    bottom: 0;
    box-shadow:
      inset 0 1px 0 rgba(255, 255, 255, 0.25),
      inset 0 -1px 0 rgba(255, 255, 255, 0.25);
    background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(var(--marbles-container-dark-blue), 1) 100%
      ),
      radial-gradient(
        ellipse farthest-side at center top,
        rgba(255, 255, 255, 0.25) 0%,
        rgb(var(--marbles-container-dark-blue)) 150%,
        rgb(var(--marbles-container-dark-blue)) 100%
      );
    opacity: 0.6;
    z-index: 2;
    border-radius: 12px;
  }

  @keyframes reveal-opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .trends-overlay__buttons {
    position: absolute;
    right: 5px;
    left: 5px;
    top: 8px;
    animation: reveal-opacity 2s;
    animation-timing-function: steps(2);

    @media screen and (max-width: 768px) and (pointer: coarse) {
      top: 8px;
      right: 6px;
    }
    .default-btn {
      position: absolute;
      right: 0;
      height: 34px;
      width: 34px;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        width: 25px;
        height: 25px;
        border-radius: 999em;
        padding: 0;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  &__content {
    position: relative;
    z-index: 3;

    .race-stats {
      padding: 15px 0 0;

      .race-stats__table {
        margin: 0;
      }
    }

    h3 {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .view-all-stats {
      display: block;
      margin-top: 20px;
      padding: 10px 15px;
      background: rgba(var(--yellow), 1);
      color: #000;
      border: none;
      border-radius: 5px;
      font-family: var(--font-formula-stack);
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background: rgba(var(--yellow), 0.8);
      }
    }
  }
}
