.marbles-game .game-top-toolbar {
  position: absolute;

  @media (max-width: 768px) and (pointer: coarse) {
    position: sticky;
    top: 0;
    width: 100%;
  }
}

.marbles-game .game-top-toolbar .game-top-toolbar__inner {
  padding: 50px 20px 20px 20px;

  .game-top-toolbar__right {
    right: 40px;
  }

  @media screen and (max-width: 768px) and (pointer: coarse) {
    padding: 15px 20px;

    .game-top-toolbar__right {
      right: 0;
    }
  }
}

.game-top-toolbar {
  background: linear-gradient(rgba(var(--black-color), 0.8), rgba(var(--black-color), 0));
  transform-origin: top left;
  position: fixed;
  margin: 0 auto;
  z-index: 300;
  top: 0;
  left: 0px;
  right: 0px;

  @media screen and (pointer: fine) {
    min-width: 1120px;
  }

  //Mobile styling

  .game-top-toolbar__inner {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
  }

  .game-top-toolbar__left {
    transform-origin: top left;
  }

  .game-top-toolbar__right {
    transform-origin: top right;
    margin-left: auto;
  }

  .game-top-toolbar__left,
  .game-top-toolbar__right {
    display: flex;
    gap: 5px;
    position: relative;
    z-index: 9;
    max-height: 44px;
  }

  .broadcast-controls {
    display: flex;
    gap: 5px;
  }
}

.toolbar-info__dropdown {
  .toolbar-info__dropdown__table {
    border-radius: 0 0 10px 10px;
    margin: 0;
  }
}

.toolbar-info__dropdown__table {
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-sizing: border-box;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 10px;

  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 10px;
    font-family: var(--font-formula-stack);
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    padding: 10px;
    box-sizing: border-box;
  }
  .body {
    display: grid;
    grid-template-columns: 1fr;

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      font-size: 10px;
      color: #fff;
      padding: 10px;
      box-sizing: border-box;
      align-items: center;

      .label {
        display: flex;
        flex-direction: column;
        gap: 5px;
        svg {
          width: 10px;
          height: 10px;
          fill: currentColor;
          flex-shrink: 0;
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
        }
        .line {
          display: flex;
          flex-direction: row;
          gap: 2px;
          align-items: center;
          font-weight: 600;
        }
        .circle {
          width: 4px;
          height: 4px;
          flex-shrink: 0;
          border-radius: 50%;
          border: 2px solid rgba(var(--yellow), 0.4);

          &.tomato {
            border-color: rgba(var(--tomato), 0.4);
          }
          &.purple {
            border-color: rgba(var(--purple), 0.4);
          }
          &.yellow {
            border-color: rgba(var(--yellow), 0.4);
          }
        }

        .yellow {
          animation: text-fancy-color 1.5s linear infinite reverse;
          background: linear-gradient(
            90deg,
            rgba(255, 234, 153, 1) 10%,
            rgba(var(--yellow), 1) 50%,
            rgba(255, 234, 153, 1) 60%
          );

          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--yellow), 1);
          -webkit-text-fill-color: transparent;
        }
        .tomato {
          animation: text-fancy-color 1.5s linear infinite reverse;
          background: linear-gradient(
            90deg,
            rgba(253, 163, 147, 1) 10%,
            rgba(var(--tomato), 1) 50%,
            rgba(253, 163, 147, 1) 60%
          );
          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--tomato), 1);
          -webkit-text-fill-color: transparent;
        }
        .purple {
          animation: text-fancy-color 1.5s linear infinite reverse;

          background: linear-gradient(
            90deg,
            rgba(241, 167, 255, 1) 10%,
            rgba(var(--purple), 1) 50%,
            rgba(241, 167, 255, 1) 60%
          );
          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--purple), 1);
          -webkit-text-fill-color: transparent;
        }
      }

      &:nth-child(even) {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
}

.toolbar-info {
  display: flex;
  position: relative;
  .toolbar-info__button {
    align-items: center;
    display: flex;
    position: relative;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 10px;
    gap: 5px;
    font-size: 10px;
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    font-family: var(--font-formula-stack);
    cursor: pointer;
    user-select: none;
    overflow: hidden;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      padding: 0;
    }

    .track-race-round {
      font-size: 8px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
    }

    .toolbar-info__button__inner {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    span {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;

      .connection-status-wrapper {
        display: inline-flex;
        align-items: center;
        margin-left: 2px;
        .connection-status-icon {
          width: 16px;
          height: 16px;

          &.connection-status-icon--warning {
            fill: rgb(var(--yellow));
          }

          &.connection-status-icon--poor {
            fill: rgb(var(--tomato));
          }
        }
      }
    }
    svg {
      position: relative;
      z-index: 3;
      width: 16px;
      height: 16px;
      margin-left: 4px;
      fill: currentColor;
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
      transition: all 450ms ease-in-out;
      perspective: 1000px;
      transform-style: preserve-3d;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        display: none;
      }
    }

    &:active {
      transform: scale(0.95);
    }
    &.active,
    &:hover {
      backdrop-filter: var(--primary-backdrop-filter-light);
      -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
      background: rgba(var(--primary-component-background), 0.6);

      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

      color: rgb(var(--yellow));

      @media screen and (max-width: 768px) and (pointer: coarse) {
        background: transparent;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .toolbar-info__dropdown {
    position: absolute;
    left: 0;
    top: 56px;
    width: 100%;
    min-width: 360px;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 999;

    backdrop-filter: var(--primary-backdrop-filter);
    -webkit-backdrop-filter: var(--primary-backdrop-filter);
    background: rgba(var(--primary-component-background), 0.8);
    box-shadow: var(--primary-background-shadow);

    user-select: none;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      left: 10px;
      width: calc(100% - 20px);
      top: 64px;
      min-width: auto;
      position: fixed;
    }
  }
  .toolbar-info__dropdown__header {
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
    overflow: hidden;
    position: relative;
    border-radius: 10px 10px 0 0;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.25);
    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
    span {
      position: relative;
      z-index: 3;
    }
  }

  .toolbar-info__dropdown__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.25);
    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));

    .entry {
      display: grid;
      grid-template-columns: 1fr 2fr;
      font-size: 12px;
      gap: 5px;
      position: relative;
      z-index: 3;
      align-items: center;

      span:first-child {
        color: rgba(255, 255, 255, 0.7);
      }

      .connection-status-text {
        &.connection-status-text--good {
          color: rgb(var(--green));
        }
        &.connection-status-text--warning {
          color: rgb(var(--yellow));
        }

        &.connection-status-text--poor {
          color: rgb(var(--tomato));
        }
      }
    }
  }
}

.toolbar-player {
  display: flex;
  position: relative;

  .toolbar-button {
    height: 44px;
    border-radius: 10px 24px 24px 10px;
    padding: 0 11px 0 10px;
    gap: 10px;
  }
}

.toolbar-button {
  display: flex;
  padding: 0 10px;
  height: 40px;
  gap: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

  backdrop-filter: var(--primary-backdrop-filter-light);
  -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
  background: rgba(var(--primary-component-background), 0.6);

  border-radius: 12px;
  box-sizing: border-box;
  justify-content: center;
  appearance: none;
  overflow: hidden;
  user-select: none;
  position: relative;

  svg {
    width: 16px;
    height: 16px;
    fill: rgba(255, 255, 255, 0.85);
    flex-shrink: 0;
    position: relative;
    z-index: 3;
    transition: all 250ms ease-in-out;
  }

  @media screen and (max-width: 768px) and (pointer: coarse) {
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);

    svg {
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
    }

    .player-balance {
      border-right: none;
    }
  }

  &:active {
    transform: scale(0.95);
  }
  &:hover {
    svg {
      fill: currentColor;
    }
    .hamburger-icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  &.active {
    svg {
      fill: rgb(var(--green));
    }
    .hamburger-icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  &:hover {
    color: rgb(var(--marbles-yellow));
  }
}

.game-toolbar-box,
.game-toolbar-btn {
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  font-variant-numeric: tabular-nums;
  background: transparent;

  appearance: none;
  user-select: none;

  svg {
    width: 14px;
    height: 14px;
    position: relative;
    z-index: 3;
    fill: currentColor;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
  }
  > div {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &.back-btn {
    gap: 6px;
    svg:first-child {
      width: 12px;
      height: 12px;
    }

    .ico-marbles-symbol_svg__ico-marbles-symbol {
      width: 30px;
      height: 30px;
      transition: all 500ms ease-in-out;
      transform-style: preserve-3d;
      transform-origin: center;
      animation: rotate-symbol 3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    @keyframes rotate-symbol {
      0% {
        transform: rotate(0deg);
      }
      33% {
        transform: rotate(-40deg);
        scale: 1.05;
      }
      66% {
        transform: rotate(40deg);
        scale: 0.95;
      }
      100% {
        transform: rotate(0deg);
        scale: 1;
      }
    }

    &:hover {
      .ico-marbles-symbol_svg__ico-marbles-symbol {
        transform: rotateY(180deg);

        rect {
          stroke: rgb(var(--marbles-yellow));
        }
        path {
          fill: rgb(var(--marbles-yellow));
          stroke: rgb(var(--marbles-yellow));
        }
      }
    }
  }

  span {
    position: relative;
    z-index: 3;
    font-size: 10px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    font-weight: 600;
    font-family: var(--font-formula-stack);
    font-variant-numeric: tabular-nums;
  }
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    color: rgb(var(--marbles-yellow));
    backdrop-filter: var(--primary-backdrop-filter-light);
    -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
    background: rgba(var(--primary-component-background), 0.6);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

    @media screen and (max-width: 768px) and (pointer: coarse) {
      background: none;
      box-shadow: none;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
    }
  }
}

.player-balance {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  z-index: 3;
  height: 100%;

  .inner {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .label {
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      font-family: var(--font-formula-stack);
      display: flex;
      color: rgba(255, 255, 255, 0.7);
      align-items: center;
      gap: 5px;

      svg {
        width: 12px;
        height: 12px;
        fill: rgba(255, 255, 255, 0.7);
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
      }
    }
    .value {
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      font-variant-numeric: tabular-nums;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        font-size: 12px;
      }
    }
  }
}

.bet-placed {
  color: rgb(var(--yellow));
  animation: pulse 1.4s ease-in-out;
}

.positive {
  color: rgb(var(--green));
  animation: pulse 1.4s ease-in-out;
}

.negative {
  color: rgb(var(--red));
  animation: pulse 1.4s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
